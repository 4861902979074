export default {
  updateFooterState({ footerState }, payload) {
    Object.assign(footerState, payload)
  },
  updateFloatingInfo({ footerState }, payload) {
    Object.assign(footerState, {
      ...footerState,
      floatingInfo: {
        ...footerState.floatingInfo,
        payload,
      }
    })
  },
  changeBtnContainerWidth({ footerState }, width) {
    footerState.btnContainerWidth = width
  },
  updatePriceDetailVisible({ footerState }, visible) {
    footerState.isPriceDetailVisible = visible
  }
}
