
export default () => ({
  commonDialogState: {
    mixPrivacy: {
      show: false,
      locationId: '',
      needPop: false
    },
    nullCartDialog: {
      show: false,
      tip: ''
    },
    paymentCouponDrawer: {
      show: false,
      paymentCouponList: [],
      lastCheckoutParams: {} // 最后一次checkout的请求参数
    },
    confirmPaymentCoupon: {
      show: false,
      isSelectCouponsBinError: false,
      lastCheckoutParams: {},
      errType: '',
      params: {}
    },
    cartLimitDialog: {
      show: false,
      tip: ''
    },
    giftcardLimitDialog: {
      show: false,
      tip: '',
      clickConfirm: false
    },
    commonErrDialog: {
      show: false,
      tip: '',
      cb: null,
      hor: false,
      showClose: false,
      onClose: null,
      onConfirm: null,
      confirmText: '',
      cancelText: ''
    },
    soldOutDrawer: {
      show: false,
      tip: '',
      soldOutList: []
    },
    specialGoodsDrawer: {
      show: false,
      hintType: '',
      tip: '',
      specialGoods: []
    },
    knivesGoodsDrawer: {
      show: false,
      goodsList: [],
      title: '',
      popup_tip: '',
      lower_button: '',
      upper_button: ''
    },
    currencyChangeDialog: {
      show: false,
      tip: ''
    },
    codConfirmDialog: {
      show: false,
      tip: '',
      coupon: [],
      points: '',
      scene: '',
    },
    riskConfirm: {
      show: false,
    },
    binCouponsDrawer: {
      show: false,
      nowUseCouponInfo: [],
      errType: '',
      paymentCoupon: []
    },
    discountLimitDrawer: {
      show: false,
      overLimitIntercept: {}
    },
    taxRelief: {
      showDialog: false,
      dialogInfo: {},
      hasNotice: false,
      showNotice: false,
      noticeInfo: {},
      isNoticeShown: false
    },
    createOrderBinDialog: {
      show: false,
      coverData: {},
      onClose: null,
      onConfirm: null
    },
    switchTransportation: {
      show: false,
      shippingList: [],
      coupon: null,
      mallCode: '',
      tips: {}
    },
    counpontTransportation: {
      show: false,
      mallCode: '',
      shippingList: [],
      params: {}
    }
  }
})
