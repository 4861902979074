export const LayoutType = {
  None: 0, // 不展示
  Single: 1, // 单商品布局风格
  Multi: 2, // 多商品布局风格
  SingleDetail: 3, //展示更多的商品，有弹窗，但是展示单商品
}

export const singleImageRowCardConfig = {
  code: 'goodsList',
  daEventExposeId: '1-55-3-2',
  daEventClickId: '1-55-3-3',
  daEventCarId: '1-55-3-4',
  useOwnClickAddBagAnalysis: true,
  from: 'goods_list',
  showTitle: true,
  showAddBagBtn: true, // 加车按钮
  disableMainimgJump: true,
  style: {
    addBagBtnStyle: 'AddText',
    loadingMode: 'light'
  },
  // 快速发货
  quickShip: true,
  showQuickShip: true,
  // 用户行为标签
  showUserBehaviorLabel: true,   
  showSellingPoint: true,
  // 角标 优质卖家
  showBadge: true,
  // 折扣显示名称前
  showDiscountLabelBeforeGoodsName: true,
  showDiscountLabelBeforeGoodsNameHighPriority: true,
  // 到手价
  showEstimateTag: true,
  showEstimatedPriceOnSale: true,
  noNeedAnyEstimatedPriceTag: true,
  // 展示原价划线价
  showRetailPrice: true
}

export const multipleImageRowCardConfig = {
  code: 'goodsList',
  daEventExposeId: '1-55-3-2',
  daEventClickId: '1-55-3-3',
  daEventCarId: '1-55-3-4',
  useOwnClickAddBagAnalysis: true,
  from: 'goods_list',
  ableDeleteWishlist: false,
  showSpuImg: false, // spu图
  showBadge: false, // 左下角角标
  showAddToBoard: false, // 加组弹窗
  showMultiColor: false, // 色块
  showAddWishlist: false, // 收藏按钮
  showSeriesBrand: false, // 品牌/系列
  showPromotion: false, // 促销
  showVideoIcon: false, // 视频图标
  showLocalSeller: false, // 本地卖家角标
  showQuickShip: false, // 快速发货标签
  showSheinClubDiscountValue: false, // 付费会员折扣
  showNewFlashPriceOnSale: false, // 新型闪购价格（替换原售价的位置）
  showNewFlashLabelUpPrice: false, // 新型闪购标签
  showDiscountLabelAfterPrice: true, // 折扣标签
  showPromoLabel: false, // 大促标签
  showBestDealLabel: false, // 保留款
  showFollowLabel: false, // 跟价款
  showUserBehaviorLabel: false, // 用户行为标签
  showUserCommentKeywordsLabel: false, // 用户评论关键词标签
  showBeltLabel: false, // 腰带
  showAdultProductDialog: false,
  showDecisionAttr: false, // 决策属性
  showRankingList: false, // 榜单
  showNewDiscountLabel: false, // 折扣标签ABT
  showGreySellingPoint: false, // 卖点标签ABT
  showSheinClubDiscountValueAfterPrice: false, // 付费会员折扣小标签
  showSheinClubPriceOnSale: false, // 付费会员价格（替换原售价的位置）
  showSheinappwordsize: false, // 价格字体ABT
  showGoodsSpace: false, // 商品下面内容标签间距ABT
  showPclistCropping: false, // 商品图裁剪ABT
  showLabelColumn: false, // 列表标签云显示与否ABT
  showImgMask: false, // 图片蒙层
  useHoverEffect: false, //hover效果
  showAddBagBtn: true, // 加车按钮,
  disableMainimgJump: true,
  showAddBagBtnBottomRight: true,
}

// 点击来源
export const ClickSource = {
  // 加车按钮
  Button: 'Button',
  // 商品图片
  Image: 'Image',
  // 属性选择按钮
  Attribute: 'Attribute',
}

// 加车来源
export const AddingSource = {
  // 下单页面
  Page: 'Page',
  // 商品列表（仅 3 有）
  Drawer: 'Drawer',
}

// 更新时机
export const UpdateMoment = {
  // 购物车数据更新后
  AfterCart: 'AfterCart',
  // 动画结束后
  AfterAnimation: 'AfterAnimation',
}

// 上报事件
export const ReportEvent = {
  // 点击加车按钮
  ClickButton: 'ClickButton',
  // 点击商品图
  ClickImage: 'ClickImage',
  // 点击属性选择按钮
  ClickAttribute: 'ClickAttribute',
  // 加车后
  AfterAdding: 'AfterAdding',
  // 加车成功后
  AfterAddingSuccess: 'AfterAddingSuccess',
}

export const clickEventMap = {
  [ClickSource.Button]: ReportEvent.ClickButton,
  [ClickSource.Image]: ReportEvent.ClickImage,
  [ClickSource.Attribute]: ReportEvent.ClickAttribute,
  [ReportEvent.AfterAdding]: ReportEvent.AfterAdding,
  [ReportEvent.AfterAddingSuccess]: ReportEvent.AfterAddingSuccess,
}

export const ActivityType = {
  Freeshipping: 'freeshipping',
  Coupon: 'coupon',
  Quickship: 'quickship',
}
export const ActivityTypeList = [
  ActivityType.Freeshipping,
  ActivityType.Coupon,
  ActivityType.Quickship,
]

export const SkuType = {
  Single: 'Single',
  Multi: 'Multi',
}
