export default () => ({
  paymentState: {
    // 选中的支付方式信息
    selectedPaymentInfo: {},
    payBsLanguage: {},
    // 下单页二次支付标识
    needSecondPayment: {
      createRes: {},
      isNeed: false
    },
    createdOrderInfo: {}, // 成单接口返回的订单信息
    appendSortedPrice: [], // 附加价格
    payInstanceEvent: {
      getIsShowPrePayModal: null, // 获取目前是否展开了卡支付前置
      getComponentStatus: null, // 获取支付组件状态，如弹窗是否打开等
      handlerPrePayCouponDrawer: null, // 获取目前是否展开了卡支付前置卡 bin 优惠券不可用抽屉
      getValidateByPay: null, // 获取校验方法
      updateCvvDrawerInfo: null, // 更新cvv错误信息
      focusNotSelectPayment: null,
      getLocalStatus: () => {}, // 获取本地状态
    }, // 支付方式列表抛出来的方法
    recordPaymentOptionsConfig: { // 支付方式配置记录
      foldedPosition: null // 支付方式不折叠 - 楼层置底
    },
    noSelectPayment: false, // 未选中支付方式提示
    clickContinueResultInfo: {
      failure_reason: '', // 失败原因 属性值格式为：{code:xxx,result:xxx,type:xxx}，其中result（哪里出错）和type（什么错误类型）两部分的规则如下： 当卡号错误时，result的值为card_num
      failure_type: '', // 失败类型 front_end/rear_end
      instalment_num: '',
      is_remember: '',
      order_id_list: '', // 每个子订单号之间用 隔开
      order_no: '',
      payment_method: '',
      prime_deduction: '', // 付费会员抵扣金额
      product_type: '', // 产品类型 单独购付费会员发membership； 单独购超省卡发sheinsaver； 其他订单类型发commontype
      scene: '', // 场景类型 新卡上报new，非新卡上报token
      status: '',
      pay_response_time: '',
      stay_time: '', // 单位是“秒” 记录从进入卡支付页面到点击continue按钮的时长
      token_id: '', // token_id
      uorder_id: '', // 关联订单号 
      is_cvvnolimit: '0', // 是否需要cvv 1 需要 0 不需要
    },
    localStatus: {}, // 本地状态
    isShowBSrcPhoneEmailConfirmDialog: false, // 是否展示bSrcPhoneEmailConfirmDialog
    c2pPhoneAndEmailInfo: {}, // c2p token 信息收集
    c2pNewCardPayFn: null, // c2p new card pay function
  }
})
