import header from './header'
import footer from './footer'
import addressBar from './addressBar'
import shoppingBags from './shoppingBags'
import shippingMethod from './shippingMethod'
import payment from './pay'
import commonDialog from './commonDialog'
import primeSaver from './primeSaver'
import couponList from './couponList'
import virtualAssets from './virtualAssets'
import buyTogether from './buyTogether'
import handleDataMapping from './dataMapping'
import { findPaymentItem } from '@/public/src/pages/checkout_new/utils/index.js'

export default {
  ...header,
  ...footer,
  ...shoppingBags,
  ...addressBar,
  ...shippingMethod,
  ...payment,
  ...commonDialog,
  ...primeSaver,
  ...couponList,
  ...virtualAssets,
  ...buyTogether,
  // 滚动过程触发动画
  changePagePriceTotalShowInView(state, payload) {
    state.isPagePriceTotalShowInView = payload
  },
  asyncDataMutations(state, context) {
    Object.assign(state, {
      ssrPageType: context.ssrPageType,
      prerenderSsr: context.prerenderSsr,
      userInfo: context.user,
      env: context.env,
      payBsLanguage: context.paymentsLanguageData || {},
      locals: context.locals,
      language: context.language,
      abtInfo: context.abtInfo || {},
      checkout: context.checkout,
      apolloConfig: context.apolloConfig || {},
      buyNowNo: context.checkout_no,
      urlPaymentCode: context.urlPaymentCode || '',
      cartsInfo: context.cartsInfo,
      checkoutNotice: context.checkoutNotice,
      assistPolicy: context.assistPolicy,
      cacheInfo: context.checkoutCache,
      nowCurrency: context.locals?.currency,
      initialSsrParamsInfo: context.initialSsrParams,
      isPaid: context?.isPaid ?? false
    })
    // 数据映射
    handleDataMapping(state, context)
  },
  assignState(state, payload) {
    Object.assign(state, payload)
  },
  // 有使用场景，记录调用完成checkout接口的结果
  checkoutComplete(state, payload) {
    // console.log('checkoutComplete==????==', payload)
  },
  /**
   *
   * 更新后续需要一直携带的不通过checkout回参刷新的入参数据
   * @param {*} params 本次成功调用checkout接口的入参
   */
  handleCheckoutParams(state, params = {}) {
    Object.assign(state.checkoutParams, {
      prime_product_code:
        params?.prime_product_code ?? state.checkoutParams.prime_product_code,
      save_card_product_code:
        params?.save_card_product_code ??
        state.checkoutParams.save_card_product_code,
      card_no: params?.card_no ?? state.checkoutParams.card_no,
      card_pin: params?.card_pin ?? state.checkoutParams.card_pin,
      is_bind_gift_card: params?.is_bind_gift_card ?? state.checkoutParams.is_bind_gift_card,
      add_coupon: params?.add_coupon ?? state.checkoutParams.add_coupon,
    })
  },
  /**
   * @description: 更新地址相关入参，如果没有地址的时候，需要从initialSsrParamsInfo取缓存的数据
   * @param {*} state store
   * @param {*} checkout 本次成功调用checkout接口的入参
   */
  handleCheckoutAddressParams(state, checkout) {
    if (!checkout.address) {
      const { address_id, country_id } = state.initialSsrParamsInfo || {}
      Object.assign(state.checkoutParams, {
        address_id,
        country_id,
      })
    } else {
      const {
        addressId: address_id,
        countryId: country_id,
        state: address_state,
        city,
        postcode
      } = checkout.address || {}
      Object.assign(state.checkoutParams, {
        address_id,
        country_id,
        state: address_state,
        city,
        postcode
      })
    }
  },
  // 更新运输方式相关入参
  handleCheckoutShippingParams(state, checkout) {
    const {
      shipping_methods_mall = [],
      address,
      mall_price_list = [],
      quick_shipping,
      cartGroupInfo = {},
    } = checkout || {}

    const showNewCartGroup = +cartGroupInfo?.showNewCartGroup === 1


    let isShopTransit = false
    let shop_transit_country_id = ''
    let user_shipping_methods = []
    if(showNewCartGroup) {
      // 取 第一个mall选中的运输方式的宅店配类型 来判断当时是否店配
      isShopTransit = +cartGroupInfo?.mallList?.[0]?.groupList?.find(_ => +_.shippingInfo?.for_checkout_param === 1)?.shippingInfo?.shipping_methods?.find(_ => +_.isDefault === 1)?.is_shop_transit === 1
      shop_transit_country_id = isShopTransit ? address?.countryId : ''


      // 运输方式
      cartGroupInfo?.mallList?.forEach(mall => {
        const targetGroup = mall?.groupList?.find(_ => +_.shippingInfo?.for_checkout_param === 1)
        const targetShippingMethod = targetGroup?.shippingInfo?.shipping_methods?.find(method => +method.isDefault === 1)

        user_shipping_methods.push({
          mall_code: mall.mallCode,
          transport_type: targetShippingMethod?.transport_type,
        })
      })
    } else {
      // 取 第一个mall选中的运输方式的宅店配类型 来判断当时是否店配
      isShopTransit = +shipping_methods_mall?.[0]?.shipping_methods?.find(
        _ => +_.isDefault === 1
      )?.is_shop_transit === 1
      shop_transit_country_id = isShopTransit ? address?.countryId : ''


      // 运输方式
      shipping_methods_mall?.forEach(mall => {
        const targetShippingMethod = mall.shipping_methods?.find(
          method => +method.isDefault === 1
        )
        user_shipping_methods.push({
          mall_code: mall.mall_code,
          transport_type: targetShippingMethod?.transport_type,
          switch_qs_flag: quick_shipping?.switch_qs?.switch_qs_selected // qs跨境运费（目前仅支持单mall站点）
        })
      })
    }
    

    // 运费险
    const insurance_info_mall =
      mall_price_list?.map(mall => {
        return {
          mall_code: mall.mall_code,
          use_insurance: mall.insurance_info?.is_use_insurance
        }
      }) || []
    user_shipping_methods = user_shipping_methods.map(mall => {
      const targetInsurance = insurance_info_mall?.find(
        _ => _.mall_code == mall.mall_code
      )

      return { ...mall, use_insurance: targetInsurance?.use_insurance ?? 0 }
    })

    Object.assign(state.checkoutParams, {
      shop_transit_country_id,
      shipping_methods: user_shipping_methods,
    })
  },
  // 更新运输方式免邮凑单信息入参
  handleCheckoutFreeShippingAddParams(state, checkout) {
    // 目前仅支持单mall站点，仅取第一个mall的数据做逻辑即可
    const { shipping_methods_mall = [] } = checkout
    const targetShippingMethod =
      shipping_methods_mall?.[0]?.shipping_methods?.find(
        method => !!method.free_shipping_add_item?.show_tip?.tip
      )
    const targetAddInfo = targetShippingMethod?.free_shipping_add_item

    if (!targetAddInfo) return

    let curr_free_shipping_add_info = {
      mall_code: shipping_methods_mall?.[0]?.mall_code,
      transport_type: targetShippingMethod?.transport_type,
      add_item_type: targetAddInfo?.add_item_type,
      last_activity_type: targetAddInfo?.njActivityType,
      last_activity_id: targetAddInfo?.activity_id,
      last_coupon_code: targetAddInfo?.coupon_code
    }

    Object.assign(state.checkoutParams, {
      free_shipping_add_info: curr_free_shipping_add_info
    })
  },
  // 更新checkout其他相关入参
  handleCheckoutOtherParams(state, { checkout, isInit }) {
    if (isInit) {
      const fromPpCheckout = state.urlPaymentCode === 'PayPal-GApaypal'
      Object.assign(state.checkoutParams, {
        from_scene: fromPpCheckout ? 'paypal_checkout' : '', // 从paypal探索页面进来则传「paypal_checkout」，否则不传
        scene: !!state.buyNowNo ? 'buy_now' : '',
        checkout_no: state.buyNowNo,
        fromCartOrderBnpl: !!state.urlPaymentCode && !fromPpCheckout ? 1 : ''
      })
    }

    const { wallet_balance, point } = checkout

    Object.assign(state.checkoutParams, {
      subCurrencyCode: checkout.usedSubCurrencyCode,
      coupon_list: checkout.coupon_list?.map(_ => _.couponCode),
      use_wallet: wallet_balance?.walletPrice?.amount > 0 ? 1 : 0,
      use_wallet_amount:
        wallet_balance?.walletPrice?.amount > 0
          ? wallet_balance?.walletPrice?.amount
          : '',
      points: point?.used_point > 0 ? point?.used_point : ''
    })
  },
  handleCheckoutPaymentParams(state, { params, checkout, isInit }) {
    const preParams = {
      payment_code: state.checkoutParams?.payment_code || '',
      payment_code_unique: state.checkoutParams?.payment_code_unique || state.checkoutParams?.payment_code || '',
      request_card_token: state.checkoutParams?.request_card_token || 1,
      token_id: state.checkoutParams?.token_id,
      installment_token_id: state.checkoutParams?.installment_token_id,
      skip_calculate_with_payment: state.checkoutParams?.skip_calculate_with_payment,
      usedCardBin: state.checkoutParams?.usedCardBin,
      hasCardBin: state.checkoutParams?.hasCardBin,
      cardPaymentFront: state.checkoutParams?.cardPaymentFront,
      selected_bin: state.checkoutParams?.selected_bin || '',
      payment_id: state.checkoutParams?.payment_id || '',
      cod_plan: state.checkoutParams?.cod_plan || '',
    }

    if (isInit) {
      Object.assign(preParams, {
        hasCardBin: 1,
        request_card_token: 1,
      })

      // bnpl场景，如果isUsedThisPayment为1，则表示需判断当前url上的paymentCode是否可用，可用则需写入入参，用于默认选中
      const matchPaymentItem = findPaymentItem(checkout?.payment_info?.payments || [], _ => +_.enabled === 1 && _.code === state.urlPaymentCode)
      if (+checkout?.payment_info?.isUsedThisPayment === 1 && matchPaymentItem) {
        Object.assign(preParams, {
          payment_code: state.urlPaymentCode || '',
          payment_code_unique: state.urlPaymentCode || '',
        })
      }
    }

    for (const key in params) {
      if (key in preParams) {
        preParams[key] = params[key]
      }
    }

    Object.assign(state.checkoutParams, preParams)
    // Object.assign(state.checkoutParams, {
    //   payment_code: params?.payment_code,
    //   payment_code_unique:
    //     params?.payment_code_unique || params?.payment_code || '',
    //   request_card_token: 1,
    //   token_id: params?.token_id,
    //   installment_token_id: params?.installment_token_id,
    //   skip_calculate_with_payment: params?.skip_calculate_with_payment,
    //   usedCardBin: params?.usedCardBin,
    //   hasCardBin: params?.hasCardBin,
    //   cardPaymentFront: params?.cardPaymentFront,
    //   selected_bin: params?.selected_bin || '',
    //   payment_id: params?.payment_id || '',
    //   cod_plan: params?.cod_plan || '',
    // })
  },
  // 更新当前页加载(页面中)的 Loading
  changeCheckoutLoading(state, { config, setLoading }) {
    const cancelTimer = () => {
      if (state.checkoutLoading.timer) {
        clearTimeout(state.checkoutLoading.timer)
        state.checkoutLoading.timer = null
      }
    }
    
    state.checkoutLoading.requesting = true
    if (setLoading) {
      cancelTimer()

      if(config.loading) {
        state.checkoutLoading.timer = setTimeout(() => {
          state.checkoutLoading.loading = true
        }, 800)
      }
      
      
      return
    }
    
    state.checkoutLoading.requesting = false
    // timer未开始时，stop
    if(config.loading) {
      cancelTimer()
      state.checkoutLoading.loading = false
    }
  },
  // 商详立即购
  handleRapidInfo(state) {
    if (typeof window == 'undefined') return
    state.buyNowFromUrl = sessionStorage?.getItem('buyNowFromUrl') || ''
  },
  updateTaxReliefInfo({ locals, commonDialogState }, { checkout }) {
    if (typeof window == 'undefined') return
    function isSameDay(target) {
      // 创建新的 Date 对象，避免修改原始对象
      const d = new Date(target)
      const d2 = new Date()

      // 设置时间部分为0
      d.setHours(0, 0, 0, 0)
      d2.setHours(0, 0, 0, 0)

      // 比较两个日期对象
      return d.getTime() === d2.getTime()
    }

    const {
      tax_preferential_pop_info: dialogInfo,
      tax_preferential_banner_info: noticeInfo
    } = checkout.tax_preferential_info || {}

    const storage = localStorage.getItem(`checkout_tax_relief_dialog_${locals.SiteUID}`)
    const dialogDisplayed = isSameDay(storage)

    // 弹窗没有展示过 && 有弹窗信息
    const showDialog = !dialogDisplayed && !!dialogInfo
    // 有政策条信息
    const hasNotice = !!noticeInfo
    // 不展示弹窗 && 有政策条信息
    const showNotice = !showDialog && hasNotice

    Object.assign(commonDialogState, {
      taxRelief: {
        ...commonDialogState.taxRelief,
        showDialog,
        dialogInfo,
        hasNotice,
        showNotice,
        noticeInfo
      }
    })
  },
  changePaymentLoadingState (state, payload = {}) {
    state.paymentLoadingState = Object.assign({}, state.paymentLoadingState, { ...payload })
  },

  // 手动叉掉notice后，清除checkoutNotice的content，从而让sticky从dom消失
  clearCheckoutNotice({ checkoutNotice }) {
    checkoutNotice.content = ''
  }
}
