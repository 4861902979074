import { ShipGoodsInvalidType } from '@/public/src/pages/checkout_new/utils/constant'
import { setShippingMethod } from './dataMapping/shippingMethod'
import { findShippingMethod } from '@/public/src/pages/checkout_new/pages/shipping_method/utils'

export default {
  // 默认选中的运输方式
  selectShippingMethod({ shippingMethodState, checkout }, { mallCode, transportType }) {
    const currMall = shippingMethodState.selectedShippingList.find(item => +item.mall_code === +mallCode)
    
    if(currMall?.transport_type === transportType) {
      console.warn('[shippingMethod] not change')
      return
    }
    
    const targetShippingItem = findShippingMethod({ shipping_methods_mall: shippingMethodState.shipping_methods_mall_adapt }, mallCode, transportType)
    
    if(!targetShippingItem || +targetShippingItem.isAvailable === 0) {
      return
    }
    
    // 上一次运输方式
    const lastTransType = currMall?.transport_type ?? ''
    
    // 设置选中  => currMall 为空，默认没选中
    if(currMall){
      shippingMethodState.selectedShippingList = shippingMethodState.selectedShippingList.map(item => {
        if (+item.mall_code === +mallCode) {
          return {
            ...item,
            transport_type: transportType,
            last_transport_type: lastTransType,
            being_check: 1
          }
        }
        
        return {
          ...item,
          being_check: 0
        }
      })
    } else {
      const { quick_shipping, mall_price_list = [], cartGroupInfo } = checkout
      const showNewCartGroup = +cartGroupInfo?.showNewCartGroup === 1
      const insuranceInfo = mall_price_list?.reduce((memo, mall) => {
        return {
          ...memo,
          [mall.mall_code]: +mall.insurance_info?.is_force_tick === 1 ? 1 : +mall.insurance_info?.is_use_insurance || 0
        }
      }, {})
      shippingMethodState.selectedShippingList = [{
        transport_type: transportType,
        last_transport_type: lastTransType,
        being_check: 1,
        mall_code: mallCode,
        use_insurance: insuranceInfo[mallCode] ?? 0,
        switch_qs_flag: !showNewCartGroup ? quick_shipping?.switch_qs?.switch_qs_selected : '' // qs跨境运费（目前仅支持单mall站点,店铺分组下不涉及）
      }]
    }

  },
  initShippingSelectedMethod(state, { checkout }) {
    setShippingMethod(state, {
      checkout
    })
  },
  recoverLastShippingMethod({ shippingMethodState }, { shippingMethodList }) {
    // 设置选中
    shippingMethodState.selectedShippingList = shippingMethodList
  },
  updateAddressRelativeError({ shippingMethodState }, addressRelativeError) {
    shippingMethodState.addressRelativeError = addressRelativeError
  },
  resetShippingLimits({ shippingMethodState }) {
    shippingMethodState.popup.goodsLimit = {
      invalidType: ShipGoodsInvalidType.NO_INVALID,
      limitInfo: {
        popupTip: '',
        title: '',
        suggestAddress: {},
        changeAddrText: '',
        changeTransportText: '',
        suggestTransportType: '',
        nextCheckedTransportType: '',
        mallCode: '',
        match_carts: [],
        suggest_shipping_method: {}
      },
      no: 0
    }
  },
  updateLimitNum({ shippingMethodState }, val) {
    if(shippingMethodState.popup.goodsLimit) {
      shippingMethodState.popup.goodsLimit.no = val
    }
  },
  updateShipPopupState({ shippingMethodState }, newVal) {
    // 临时处理，待优化
    if(newVal && newVal.goodsLimit && newVal.goodsLimit.no == null) {
      newVal.goodsLimit.no = shippingMethodState.popup.goodsLimit.no || 0
    }
    shippingMethodState.popup = {
      ...shippingMethodState.popup,
      ...newVal
    }
  },
  updateShippingState({ shippingMethodState }, newVal) {
    Object.keys(newVal).forEach((key) => {
      shippingMethodState[key] = newVal[key]
    })
  },
  updateCurrentFreightFreePopup({ shippingMethodState }, {}) {
    shippingMethodState.popup = {
      ...shippingMethodState.popup,
      shippingFee: {
        visible: true,
        mallCode: '',
        transportType: '',
      }
    }
  },
  updateShipPopupByKey({ shippingMethodState }, payload) {
    const popupState = shippingMethodState.popup
    const { key, value } = payload
    const groups = key.split('.')
    const last = groups[groups.length - 1]
    let targetState = popupState
    
    for(let i = 0; i < groups.length - 1; i++) {
      targetState = popupState[groups[i]] || {}
    }
    
    if(targetState[last] && typeof targetState[last]  === 'object') {
      targetState[last]  = {
        ...targetState[last],
        ...value
      }
    } else {
      targetState[last] = value
    }
  },
  markAddItemComplete({ checkoutParams }) {
    // ![20240312」下单页运输方式模块支持免邮活动和运费券凑单](https://arc.sheincorp.cn/docs/2733359019206893568)
    Object.assign(checkoutParams.free_shipping_add_info, {
      have_done_complete: 1
    })
  }
}
