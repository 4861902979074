import { commonPrefixCls } from '@/public/src/pages/checkout_new/utils'

export const prefixCls = commonPrefixCls.bind(null, 'checkout-sm__')

export const findShippingMethod = (checkout, mallCode, transportType) => {
  const currMall = checkout.shipping_methods_mall.find(mall => +mallCode === +mall.mall_code)
  return currMall.shipping_methods.find(shipMethod => shipMethod.transport_type === transportType)
}

export const findGroup = (cartGroupInfo = {}, mallCode = '', groupId = '') => {
  const groupMall = cartGroupInfo.mallList?.find(item => +item.mallCode === +mallCode)
  return groupMall?.groupList?.find(group => group.groupId === groupId) || null
}

/**
 *
 * @param checkout
 * @param {Array<Object>} selectedList 选中项，数组对象。
 * @returns {-1, 0, 1} -1: 多mall存在且没有一个选中项；0: 非店配；1: 店配
 */
export const getSelectedIsStore = (checkout, selectedList = []) => {
  if(!selectedList || selectedList.length === 0) return -1
  
  // 当前选中/修改的运输方式
  let beingCheckMall = selectedList[0]
  
  if(selectedList.length > 1) {
    beingCheckMall = selectedList.find(item => +item.being_check === 1) || selectedList[0]
  }
  
  const { mall_code, transport_type } = beingCheckMall
  
  const shipItem = findShippingMethod(checkout, mall_code, transport_type)
  
  return +shipItem?.is_shop_transit === 1 ? 1 : 0
}
