import { initialize } from '@shein-aidc/bs-sdk-libs-manager'
import schttp from '@/public/src/services/schttp'
import schttpSync from '@/public/src/services/schttp/sync'
import UserInfoManager from '@/public/src/services/UserInfoManager/UserInfoManager.js'
import { OestClientHandle } from '@shein-aidc/basis-oesthandle'

export const businessLibInitializeForCSR = ({ router, app }) => {
  initialize({
    schttp,
    schttpSync,
    envs: {
      project: 'pwa',
      bffEnv: gbCommonInfo.bffEnv,
      webVersion: gbCommonInfo.WEB_VERSION,
      host: gbCommonInfo.host,
      lang: gbCommonInfo.lang,
      langPath: gbCommonInfo.langPath,
      siteUID: gbCommonInfo.SiteUID,
      SERVER_TYPE: gbCommonInfo.SERVER_TYPE,
      NODE_SERVER_ENV: gbCommonInfo.NODE_SERVER_ENV,
      appLanguage: gbCommonInfo.appLanguage,
      currency: gbCommonInfo.currency,
      cssRight: gbCommonInfo.GB_cssRight,
      RESOURCE_SDK: gbCommonInfo.RESOURCE_SDK,
    },
    getters: {
      isLogin() {
        return UserInfoManager.isLogin()
      },
      memberId() {
        return UserInfoManager.get({ key: 'memberId', actionType: 'bsLibs' })
      },
      SaPageInfo() {
        return window.SaPageInfo || {}
      },
      oest() {
        return OestClientHandle.getEnptValue() || ''
      }
    },
    languages: {
      BBackIcon: {
        Back: gbCommonInfo.language.SHEIN_KEY_PWA_15234,
      },
      BMessageBar: {
        Message: gbCommonInfo.language.SHEIN_KEY_PWA_15201,
      },
      BPreSearchEntry: {
        Search: gbCommonInfo.language.SHEIN_KEY_PWA_30497,
      },
      BWishListEntry: {
        Save: gbCommonInfo.language.SHEIN_KEY_PWA_15497,
      },
      BHeaderCart: {
        Cart: gbCommonInfo.language.SHEIN_KEY_PWA_15708,
      },
    },
    router,
  }, app)
}


