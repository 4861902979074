export default {
  routeName: 'pre_search',
  preload: true, // 是否预加载， 切换路由时，不会重新请求数据
  newPosKeys: [
    'MostPopular',
    'NewPicSearch',
    'PicSearchUpgrade',
    'SearchTrendNew',
    'BackToSugg',
    'searchwordstyle',
    'SearchPerformance',
    'idpresearch',
    'idsearchdiscovery',
    'newsearch',
  ],
  posKeys: [
    'PicSearch',
    'PicSearchStrategy',
    'PicSearchExpandPop',
    'AllListQuickShip',
    'ListSearchSort',
  ],
}
  
