import { ref } from 'vue'

const changePaymentRef = ref()
const paymentOptionsRef = ref()
const title = ref('')
const tips = ref('')
const payments = ref([])
const actionsConfig = ref([])
const asideCloseCallbackFn = ref(null)

// 不符合运输方式，需要剔除的支付方式编码
const excludeTransportationCode = ref({ default: [] })

export default function useChangePaymentForShip (){
  // 缓存支付列表下沉组件实例，用户获取可用支付列表
  const updatePaymentOptionsRef = (ref) => {
    paymentOptionsRef.value = ref
  }

  const setExcludeTransporTationCode = ({ transportationMallCode = 'default', code = '' }) => {
    if(excludeTransportationCode.value[transportationMallCode]){
      !excludeTransportationCode.value[transportationMallCode].includes(code) && excludeTransportationCode.value[transportationMallCode].push(code)
    } else {
      excludeTransportationCode.value[transportationMallCode] = [code]
    }
  }

  const asideCloseCallback = (val) => {
    asideCloseCallbackFn.value && asideCloseCallbackFn.value(val)
  }

  // 更新props配置
  const updateDrawerConfig = (config = {}) => {
    const paymentsList = (config.payments || []).filter(f => !excludeTransportationCode.value[config?.transportationMallCode ?? 'default'].includes(f.code))
    title.value = config.title || ''
    tips.value = config.tips || ''
    payments.value = paymentsList || []
    actionsConfig.value = config.actions || []
    asideCloseCallbackFn.value = config.asideCloseCallbackFn
  }
  return {
    changePaymentRef,
    paymentOptionsRef,
    updatePaymentOptionsRef,
    title,
    tips,
    payments,
    actionsConfig,
    updateDrawerConfig,
    setExcludeTransporTationCode,
    asideCloseCallback
  }

}
