export default () => ({
  togetherBuy: {
    // 随手购接口初始化完成标识，用于随手购涉及埋点，利诱点
    togetherInitFinish: false,
    addCartProducs: [],
    OrderGoodsLocation: 0,
    productsDrawerVisible: false,
  },

  /************************************
   * @description [融合如下，推全上面的全删除]
   **********************************/
  buyTogetherState: {
    recommendGoods: {},
    isRecommendApiStatus: false,
    addedCartId: '',

    // 添加到购物袋的随手购商品的id
    AddedCartsIdsFromBuyTogether: [],

    // 展示到购物袋的随手购商品的悬浮窗
    visibleShoppingBagFloatArea: false,
  },
})
