import header from './header'
import footer from './footer'
import addressBar from './addressBar'
import shoppingBags from './shoppingBags'
import pay from './pay'
import shippingMethod from './shippingMethod'
import primeSaver from './primeSaver'
import virtualAssets from './virtualAssets'
import buyTogether from './buyTogether'
import returnCoupons from './returnCoupons'
import couponList from './couponList'
import notice from './notice'

export default {
  ...header,
  ...footer,
  ...addressBar,
  ...shoppingBags,
  ...pay,
  ...shippingMethod,
  ...primeSaver,
  ...virtualAssets,
  ...buyTogether,
  ...returnCoupons,
  ...couponList,
  ...notice
}
