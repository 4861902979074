// @Notice Vue 3 upgrade: 原 mounting/ada/moveclick.js 逻辑，移至当前
/*
 *   @constructor radioGroup
 */
class RadioGroup  {
  constructor (domNode, role) {
    this.domNode = domNode
    this.role = role
    this.radioButtons = []
    this.firstRadioButton = null
    this.lastRadioButton = null
  }
  init () {
    // initialize pop up menus
    if (!this.domNode.getAttribute('role')) {
      this.domNode.setAttribute('role', 'radiogroup')
    }
  
    const rbs = this.domNode.querySelectorAll(`[role=${this.role || 'radio'}]`)
  
    for (let i = 0; i < rbs.length; i++) {
      const rb = new RadioButton(rbs[i], this)
      rb.init()
      this.radioButtons.push(rb)
  
      if (!this.firstRadioButton) {
        this.firstRadioButton = rb
      }
      this.lastRadioButton = rb
    }
    this.firstRadioButton && (this.firstRadioButton.domNode.tabIndex = 0)
  }
  setChecked (currentItem) {
    for (let i = 0; i < this.radioButtons.length; i++) {
      const rb = this.radioButtons[i]
      rb.domNode.setAttribute('aria-checked', 'false')
      rb.domNode.tabIndex = -1
    }
    currentItem.domNode.setAttribute('aria-checked', 'true')
    currentItem.domNode.tabIndex = 0
    currentItem.domNode.focus()
    currentItem.domNode.click()
  }
  setCheckedToPreviousItem (currentItem) {
    let index
  
    if (currentItem === this.firstRadioButton) {
      this.setChecked(this.lastRadioButton)
    } else {
      index = this.radioButtons.indexOf(currentItem)
      this.setChecked(this.radioButtons[index - 1])
    }
  }
  
  setCheckedToNextItem (currentItem) {
    let index
  
    if (currentItem === this.lastRadioButton) {
      this.setChecked(this.firstRadioButton)
    } else {
      index = this.radioButtons.indexOf(currentItem)
      this.setChecked(this.radioButtons[index + 1])
    }
  }
}

/*
 *   @constructor RadioButton
 */
class RadioButton {
  constructor(domNode, groupObj) {
    this.domNode = domNode
    this.radioGroup = groupObj
  
    this.keyCode = Object.freeze({
      RETURN: 13,
      SPACE: 32,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40,
    })
  }
  init () {
    this.domNode.tabIndex = -1
    this.domNode.setAttribute('aria-checked', 'false')
  
    this.domNode.addEventListener('keydown', this.handleKeydown.bind(this))
    this.domNode.addEventListener('click', this.handleClick.bind(this))
    this.domNode.addEventListener('focus', this.handleFocus.bind(this))
    this.domNode.addEventListener('blur', this.handleBlur.bind(this))
  }
  
  /* EVENT HANDLERS */
  handleKeydown (event) {
    let flag = false
  
    switch (event.keyCode) {
      case this.keyCode.SPACE:
      case this.keyCode.RETURN:
        this.radioGroup.setChecked(this)
        flag = true
        break
  
      case this.keyCode.UP:
        this.radioGroup.setCheckedToPreviousItem(this)
        flag = true
        break
  
      case this.keyCode.DOWN:
        this.radioGroup.setCheckedToNextItem(this)
        flag = true
        break
  
      case this.keyCode.LEFT:
        this.radioGroup.setCheckedToPreviousItem(this)
        flag = true
        break
  
      case this.keyCode.RIGHT:
        this.radioGroup.setCheckedToNextItem(this)
        flag = true
        break
  
      default:
        break
    }
  
    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }
  
  handleClick () {
    this.radioGroup.setChecked(this)
  }
  
  handleFocus () {
    this.domNode.classList.add('focus')
  }
  
  handleBlur () {
    this.domNode.classList.remove('focus')
  }
}


const MoveClickDirective = {
  mounted(el, binding) {
    new RadioGroup(el, binding.value).init()
  },
}

export default MoveClickDirective
