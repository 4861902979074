import { ShipGoodsInvalidType } from '@/public/src/pages/checkout_new/utils/constant'
import useWakeAddressWrite from '@/public/src/pages/checkout_new/pages/address_bar/hooks/useWakeAddressWrite.js'
import { OPERATE_TYPE, TRANSPORT_TYPE } from '@/public/src/pages/checkout_new/pages/address_bar/utils/constant.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useShippingSelect } from '@/public/src/pages/checkout_new/pages/shipping_method/hooks/useShippingSelect'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import useChangePaymentForShip from 'public/src/pages/checkout_new/pages/payment_options/hooks/useChangePaymentForShip.js'

export default {
  async handleShippingMethodResp({ state, commit }, { code: _code, info = {}, params }) {
    const { onAddressChange } = useShippingSelect(this)
    const { shippingMethodState: { popup: { goodsLimit } } } = state
    
    try {
      info = info || {}
      info.shipping_pre_interception = info?.shipping_pre_interception || {}
      // https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1424675430
      // https://soapi.sheincorp.cn/application/7942/routes/post_order_order_checkout/doc
      let { shipping_pre_interception } = info ?? {}
      const { limit_info: _limit_info, mall_code, transport_type, action, suggest_shipping_method, address_edit } = shipping_pre_interception
      let limit_info = _limit_info || {}
      let code  = +_code
      let hintType = +limit_info.hint_type
      const { handleAddressDrawer } = useWakeAddressWrite(this)

      const saTrackLimit = () => {
        daEventCenter.triggerNotice({
          id: 'expose_interception_shippingmethods:simple',
          data: {
            rules_type: hintType || '',
            no: goodsLimit?.no || '',
          }
        })
      }
      
      daEventCenter.triggerNotice({
        id: 'click_interception_shippingmethods:simple',
        data: {
          rules_type: hintType || '',
          shipping_method_id: transport_type,
          mall_code: mall_code
        }
      })
      
      commit('updateLimitNum', goodsLimit.no + 1)
      
      if(code === 10126003) {
        // 1.商品类限制弹窗， 当且仅当hintType为2可编辑数量
        if(hintType === 1 || hintType === 2) {
          commit('updateShipPopupState', {
            goodsLimit: {
              invalidType: hintType === 1 ? ShipGoodsInvalidType.GOODS_LIMIT_NO_EDIT : ShipGoodsInvalidType.GOODS_LIMIT_CAN_EDIT,
              limitInfo: {
                popupTip: limit_info?.popup_tip || [],
                title: limit_info?.title || [],
                matchCarts: limit_info?.match_carts || [],
                mallCode: mall_code || '',
                transportType: transport_type
              }
            }
          })
          return
        }
        
        // hintType为3，地址类
        commit('updateShipPopupState', {
          goodsLimit: {
            invalidType: ShipGoodsInvalidType.GOODS_LIMIT_ADDR_INVALID,
            limitInfo: {
              title: limit_info.title || '',
              popupTip: limit_info.popup_tip || '',
              mallCode: mall_code || '',
              transportType: transport_type,
              address: limit_info.address,
            }
          }
        })
        
        saTrackLimit()
        return
      }
      
      // 3. 店配地址不匹配, hintType = 7
      if(code === 10126005) {
        if(action === 'shop_address_edit') {
          handleAddressDrawer({
            status: true,
            operateType: OPERATE_TYPE.EDIT,
            addressType: TRANSPORT_TYPE.SHOP,
            addressData: address_edit.address,
            callBack: params => onAddressChange(params, true)
          })
        } else if(action === 'suggest_shipping_method_popup') {
          commit('updateShipPopupState', {
            goodsLimit: {
              invalidType: ShipGoodsInvalidType.STORE_ADDR_NO_MATCH,
              limitInfo: {
                popupTip: suggest_shipping_method.popup_tip || '',
                mallCode: mall_code || '',
                transportType: transport_type,
                suggest_shipping_method,
              }
            }
          })
          
          daEventCenter.triggerNotice({
            id: 'expose_store_popup:simple',
          })
        }
        
        saTrackLimit()
        return
      }
      
      // 4. 宅店配切换,店配地址相关报错
      if([402502, 402501, 403370, 401906, 401973, 400322, 401972].includes(+_code)) {
        handleAddressDrawer({
          status: true,
          operateType: OPERATE_TYPE.ADD,
          addressType: TRANSPORT_TYPE.SHOP,
          addressData: state?.checkout?.address || {},
          extraData: {
            mallCode: mall_code,
            transportType: transport_type,
            needEmptyAddress: true, // 是否需要空地址
            closeIconClickFunc: () => {
              // 关闭填写抽屉需要执行
              commit('updateAddressRelativeError', false)
            }
          },
          callBack: onAddressChange,
        })
        return
      }
      
      if([10126001, 10126002].includes(code)) {
        // 宅店配互斥弹窗
        commit('updateShipPopupState', {
          goodsLimit: {
            invalidType: 10126001 === code ? ShipGoodsInvalidType.STORE_SWITCH_MUTEX_TO_STORE : ShipGoodsInvalidType.STORE_SWITCH_MUTEX_TO_HOME,
            params
          },
        })
      }

    } catch(e) {
      console.error(e)
    }
  },
  // 运输方式, 限制券/支付方式/运输方式
  async handleShippingLimitResp({ state, commit }, { code, info = {}, res, params }){
    const { shippingMethodState: { popup: { couponDrawerVisible }, selectedShippingList, shipping_methods_mall_adapt } } = state
    const { changePaymentRef, paymentOptionsRef, updateDrawerConfig, setExcludeTransporTationCode } = useChangePaymentForShip()
    const { startFetchShipping, rollbackShippingMethod } = useShippingSelect(this)

    try{
      let { shipping_methods_popup_error_info } = info ?? {}
      // shipping_methods_popup_error_info = {
      //   "payment_title_tip": "Select available Payment method",
      //   "payment_desc_tip": "The coupon used is only available for the following Payment method.",
      //   "transport_title_tip": "Select available Shipping method",
      //   "transport_desc_tip": "The coupon used is only available for the following Shipping Method",
      //   "coupon_shipping_method": ['standard_express'],
      //   "mall_code": "1"
      // }
      const { payment_title_tip, payment_desc_tip, coupon_shipping_method, mall_code: methodsMallCode, transport_title_tip, transport_desc_tip } = shipping_methods_popup_error_info ?? {}
      const shippingList = (shipping_methods_mall_adapt ?? []).find(f => f.mall_code == methodsMallCode)?.shipping_methods ?? []
      const filterShippingList = shippingList.filter(f => +f.isAvailable === 1 && coupon_shipping_method.includes(f.transport_type))

      const rollBack = () => {
        SToast(res?.msg ?? 'error')
        rollbackShippingMethod()
      }

      // shipping_methods_popup_error_info是否有值
      const checkInfo = () => {
        const needSmg = !shipping_methods_popup_error_info
        if(needSmg){
          rollBack()
        }
        return needSmg
      }

      if([300628, 301110].includes(code)){
        if(checkInfo()) return false
        if(filterShippingList.length){
          const mapDialog = {
            switchTransportation: () => {
              const { transport_title_tip, transport_desc_tip } = shipping_methods_popup_error_info ?? {}
              commit('updateCommonDialogState', {
                switchTransportation: {
                  show: true,
                  shippingList: filterShippingList,
                  mallCode: methodsMallCode,
                  tips: {
                    transport_title_tip,
                    transport_desc_tip
                  },
                  type: 'shipping_pay'
                }
              })
            },
            counpontTransportation: () => {
              commit('updateCommonDialogState', {
                counpontTransportation: {
                  show: true,
                  mallCode: methodsMallCode,
                  shippingList: coupon_shipping_method,
                  type: 'coupon_shipping',
                  params
                }
              })
            }
          }
          const type = couponDrawerVisible ? 'switchTransportation' : 'counpontTransportation'
          return mapDialog[type]()
        }
      }
      if([300512].includes(code)){
        if(checkInfo()) return false
        const { payment_code_unique, shipping_methods } = state.checkoutParams
        // 目前只有单mall场景
        const { transport_type, mall_code  } = shipping_methods[0]
        const transportationMallCode = `${transport_type}_${mall_code}`
        setExcludeTransporTationCode({ transportationMallCode, code: payment_code_unique })
        const paymentListActions = [
          {
            type: 'confirm',
            text: state.language.SHEIN_KEY_PWA_36034,
            cb: (payment) => {
              appEventCenter.emit('set-selected-payment', { ...payment, changeCheckData: { data: { shipping_methods: selectedShippingList } } })
            }
          },
          {
            type: 'custom',
            text: state.language.SHEIN_KEY_PWA_36036,
            cb: () => {
              commit('updateCommonDialogState', {
                switchTransportation: {
                  show: true,
                  shippingList: filterShippingList,
                  mallCode: methodsMallCode,
                  tips: {
                    transport_title_tip,
                    transport_desc_tip
                  },
                  type: 'shipping_pay'
                }
              })
            },
          }
        ]
        updateDrawerConfig({
          title: payment_title_tip,
          tips: payment_desc_tip,
          payments: paymentOptionsRef.value?.getEnabledPayments() || [],
          transportationMallCode,
          actions: coupon_shipping_method.length ? paymentListActions : paymentListActions.splice(0, 1),
          type: 'pay_shipping',
          asideCloseCallbackFn: (val) => {
            if(val?.type == 'close-icon'){
              startFetchShipping()
            }
          }
        })
        changePaymentRef.value?.show()
      }

      rollBack()
    }catch(err){
      console.error(err)
    }
  }
}
