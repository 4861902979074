export default {
  changeTogetherBuy(state, payload) {
    Object.keys(payload).forEach(key => {
      state.togetherBuy[key] = payload[key]
    })
  },
  changeRecommendApiStatus(state, payload) {
    state.buyTogetherState.isRecommendApiStatus = payload
  },
  changeBuyTogether(state, payload) {
    state.buyTogetherState.recommendGoods = payload
  },

  /************************************
   * @description [融合如下，推全上面的全删除]
   **********************************/ 
  changeBuyTogether(state, payload) {
    state.buyTogetherState.recommendGoods = payload
  },

  // 增加添加到购物袋的随手购商品的id
  changeAddedCartsIds(state, payload) {
    state.buyTogetherState.AddedCartsIdsFromBuyTogether = payload
  },
  // 展示到购物袋的随手购商品的悬浮窗
  changeVisibleShoppingBagFloatArea(state, payload) {
    state.buyTogetherState.visibleShoppingBagFloatArea = payload
  },
}
