import { createStore as _createStore } from 'vuex'
import { stateFactory } from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const getStoreInst = () => {
  return {
    namespaced: true,
    state: stateFactory(),
    getters,
    mutations,
    actions
  }
}
const singleSpaStore = getStoreInst()

// For MPA SSR
export function createStore() {
  return _createStore({ modules: { checkout: getStoreInst() } })
}

// For SPA
export default singleSpaStore
