import {
  setIsMultiMall,
  setLayoutMode,
  setBagsInfo,
  setQsFreightStatus,
  changeRealQuickShip,
} from './shoppingBags'
import { setAddressInfo } from './address'
import { setFooterInfo } from './footer'
import { setAbtInfo } from './abt'
import { setShippingMethod } from './shippingMethod'

/**
 * @description 处理数据映射关系【数据映射，处理来自api的数据，组装成自己想要的数据，放到对应业务的state中】
 * @param {object} 来自服务器的数据
 * */
const handleDataMapping = (state, { locals, cartsInfo, abtInfo, checkout }) => {
  /**
   * @description common mapping
   * */
  setGBcssRight(state, locals.GB_cssRight)
  setCartGroupInfo(state, checkout)

  /**
   * @description 下方业务数据映射处理，新建文件, 形如购物袋
   * */

  // 数据变更处理 mapping
  changeRealQuickShip({ cartsInfo, checkout })

  // 是否是多mall mapping
  setIsMultiMall(state, cartsInfo.is_multi_mall)

  // 布局模式 mapping
  setLayoutMode(state, { cartsInfo, abtInfo, checkout })

  // 商品项信息 mapping
  setBagsInfo(state, {
    cartsInfo,
    checkout,
    abtInfo,
  })

  setQsFreightStatus(state, { checkout })

  // 运输方式
  setShippingMethod(state, { checkout, isInit: true })

  // address mapping
  setAddressInfo(state, { checkout })

  // footer mapping
  setFooterInfo(state, { locals, checkout, abtInfo })

  // abt mapping
  setAbtInfo(state, { locals, checkout, abtInfo })

  // coupon mapping
}

/**
 * @description 站点镜像【ar il站点是true。其他是false】
 * @GB_cssRight {boolean} true ar il站点
 * @GB_cssRight {boolean} false ar 其他站点
 * */
const setGBcssRight = (state, payload) => {
  Object.assign(state, {
    GB_cssRight: payload,
  })
}

// 下单页展示店铺分组
export const setCartGroupInfo = (state, checkout) => {
  // 总开关，仅当该字段返回1才需要展示新的分组样式，中间层判断了OrderShipGroupStatus这个abt
  const cartGroupInfo = checkout?.cartGroupInfo || {}
  const showNewCartGroup = +cartGroupInfo?.showNewCartGroup === 1
  Object.assign(state, {
    showNewCartGroup,
    cartGroupInfo,
  })
}

export default handleDataMapping
