// 店铺独有的abt配置
const storeAbtConfig = {
  postKeys: [
    'PointProgram', // 店铺评论
  ]
}
// 列表相关页abt配置

export default [
  'product-list-v2', // 包含真实列表
  'search-product-list',
  'page_store',
  'under-prices',
  'picked-info-list',
  'feedback-rec-list',
  'shein_picks',
].map(routeName => {
  return {
    routeName,
    preload: true, // 是否预加载， 切换路由时，不会重新请求数据
    newPosKeys: [
      'FollowLabel',
      'imageLabel',
      'cateName',
      'SearchReco',
      'ListReco',
      'RecoPopup',
      'RealtimeFeedbackJson',
      'RealtimeFeedbacklastcate',
      'NewPosition',
      'OneTwoTitle',
      'UserBehaviorLabel',
      'listReview',
      'selectedreview',
      'selectedreviewJson',
      'PromotionalBelt',
      'NewStarRating',
      'ListAddToBag',
      'HomeRecommendFloorOtherLand',
      'HomeSelectedAll',
      'HomeSelectedAllOther',
      'HomeRecommendOtherSwitch',
      'BigCard',
      'ListFilter',
      'ListPriceFilter',
      'ListTop',
      'ListTopNew',
      'SearchTopNew',
      'CateRec',
      'CateRecJson',
      'Listcategoryscreening',
      'listSpuPic',
      'searchSpuPic',
      'ClickGoodsSearch',
      'SelectClassSortJson',
      'DetailShopItemList',
      'CartShopItemList',
      'DailyNewSortJson',
      'DailySortJson',
      'sheincluboptionsJson',
      'SortSellingPointJson',
      'listcartinfoSwitch',
      'NewInSortJson',
      'RealClassSortJson',
      'ListEntrance',
      'ListTitleType',
      'ListServer',
      'ListComponent',
      'ListShadingWord',
      'LickClickReco',
      'PsearchClickReco',
    ],
    posKeys: [
      // 'ListExpandPop', // 已推全-写死值: pop
      // 'CccListFlow',  // 已推全-写死值: ShowOrderApplication
      'SearchPageSort',
      'ListSearchSort',
      'SearchPageLimitNum',
      // 'CloudTagContent', // 已推全-写死值: cloud_tag=1&rule_id=tag_abt:pde|resort:v1|recplt_debug:1
      'Searchfilter',
      // 'PageFeedAttribute', // 废弃
      // 'CodeReminder', // 已推全-写死值: 空值
      // 'GoodsTitle', // 废弃
      // 'ListAttrSequence', // 废弃
      'SearchTopNavigation', // 图文导航搜索结果页
      'ListTopNavigation',
      // 'SearchExpandPop',// 已推全-写死值: pop
      'ListTopNavigationJson',
      'ListTopNavigationJsonNew',
      'SearchLeftFilter', // 搜索筛选filter
      'ListLeftFilter', // 列表筛选filter
      'HideGoodsNum', // 商品列表数量展示
      'Featuredreviews', // SM列表展示买家秀
      // 'NewInTTh', // 废弃
      'CategoryTTh',
      // 'OtherTTh', // 废弃
      'SearchFeedHotword',
      'SearchNoResultFeedHotword',
      'AllListPreferredSeller',
      'AllListStarReview',
      'SpuPic',
      'VideoIcon',
      ...(
        routeName === 'page_store' ? storeAbtConfig.postKeys : []
      ),
    ],
  }
})
