// @Notice Vue 3 upgrade: 原 mounting/ada/index.js 逻辑，移至当前进行重构
import ADA from '../../mounting/ada/index.js'
import adaModal from './adamodal.js'
import moveClick from './moveclick.js'

/**
 * v-ada 指令: 添加 ADA类 所依赖的自定义属性 ( Dom结构有序场景, 无需使用 v-ada)
 * v-ada="{ level: (tabIndex to set), pos: (position(s) of the element(s)), adaption, subAdaption }"
 *
 * 参数对象接收level, pos
 * @param {Number} level 指定tabIndex
 * ( 为了方便统一管理/拓展level, 必须采用业务容器配置化维护组件level值 )
 * @param {Number|Array} pos 指定元素位置, 嵌套结构应按父级到子级次序依次传入元素下标
 * *( start at 0, 保证index连续, 针对条件渲染内容, 由使用方独立维护index, 参考列表item.vue)
 * @param {Number} adaption 下一层级最大聚焦个数(用于条件渲染场景)[由于0索引原因, 可选元素集必须被包含于一个固定根]
 * ( 下一层级有条件渲染元素时, 此元素讲尝试在范围内匹配首个找到的元素, 子层级互切范围亦取决于此值 )
 *
 * e.g.
 * 单层
 * v-ada="{ level: 2, pos: 0 }"
 * v-ada="{ level: 2, pos: [1] }"
 * 嵌套
 * v-ada="{ level: 4, pos: [0,5] }"
 * 条件
 * v-ada="{ level: 2, pos: 0, adaption: 2 }"
 */
const ada = {
  mounted: ADA.setAdaData,
  updated: ADA.setAdaData
}

const enterkey = {
  // 指令的定义
  beforeMount: function (el, binding, vnode) {
    el.addEventListener('keydown', function(event){
      if ((event?.keyCode === 13 || event?.keyCode === 108) && vnode?.props?.onClick) {
        vnode.props.onClick(event)
      }
    })
  }
}

/*
 * 只需父级使用v-ada-radio-group, radio项只要添加role="radio"
 * 上下左右键切换选中，当focus的radio没有选中，可用空格与回车选中
 * demo可参考orderList.vue
 */
const adaRadioGroup = moveClick

export {
  ada,
  ada as vAda,
  enterkey,
  enterkey as vEnterkey,
  adaModal,
  adaModal as vAdaModal,
  adaRadioGroup,
  adaRadioGroup as vAdaRadioGroup,
}
