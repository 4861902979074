export default {
  routeName: 'UserIndex',
  preload: true,
  newPosKeys: [
    // bff在用
    'pagemefeedtrend',
    'pagemefeedshop',
    'listwithCoupon',
    'pwameRecommend',
    // node在用
    'WishLoadmore',
    'UserProfileAd',
    'storewishlist',
    'SaverPageSwitch',
    'SaverSwitch',
    'PriceReminder',
    'unpaidOrderMe',
    'MeCouponShow',
    'SheinclubRetain',
    'sheinclubsaveroi',
    'sheinclubEfcPage',
    'sheinclubrec',
    'ForgetPwdQA',
    'ClubScribePrice',
    'SHEINClubAutoRenewal',
    'OldUserReturnCoupon',
    'bindPaymentOption',
    'PaymentSecurity',
    'hidePaymentOptions',
    'PaymentSafetyExperiment',
    'primePagePopup',
    'SHEINClubRevision',
    'listRRP',
    'detailrrpprice',
    'PrimePageAContentManagement',
    'MyPaymentoptionsBanner',
    'PageMeNews'
  ],
  posKeys: [
    // 'WishLoadmore',
    // 'storewishlist',
    'Reviewentry',
    'FriendshipCoupon',
    'sheinclubme',
    'sheinclubfreetrial',
    'HideStudent',
    // 'GMSPromotion',
    // 'shareearn',
    // 'RecentlyChangeView',
    'orderResellEntrance',
    // 'unpaidremind',
    // 'PointsDis',
    'couponarticalpage',
    'OpenFreeTrial',
  ],
}
