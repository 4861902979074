import schttp from '@/public/src/services/schttp'
import { isEqual } from '@/public/src/pages/checkout_new/utils'

export default {
  /**
   * @description 更新购物车接口
   * @param {*} opts 仅为购物车接口入参
   */
  updateCartsInfoData({ commit, state }, opts = {}) {
    const params = {
      country_id: opts.country_id ?? state.checkoutParams.country_id,
      prime_product_code:
        opts.prime_product_code ?? state.checkoutParams.prime_product_code,
      bag_show_style: 1,
      scene: state.checkoutParams.scene,
      checkout_no: state.checkoutParams.checkout_no,
      userLocalSizeCountry:
        typeof window !== 'undefined'
          ? window.localStorage.getItem('last_select_country')
          : ''
    }
    return schttp({
      method: 'POST',
      url: '/order/get_carts_info_for_order_confirm',
      data: params,
      useBffApi: true
    }).then(res => {
      const isRender = isEqual(res?.code, 0)
      if (isRender) {
        // 更新cartsInfo
        commit('assignState', { cartsInfo: res.info })

        // 更新render数据
        commit('updateShoppingBagsData', {
          cartsInfo: res.info,
          checkout: undefined
        })
      }
      return res
    })
  },
  /**
   * @description 更改购物袋商品选中状态，用新状态去请求计价
   * @param {*} opts 仅为接口入参
   */
  modifyCartCheckStatusHttp({}, opts = {}) {
    const params = {
      operation_type: opts.operation_type,
      cart_id_list: opts.cart_id_list,
      append_id_list: opts.append_id_list
    }

    return new Promise((resolve, reject) => {
      schttp({
        url: `/order/mall/modify_cart_check_status`,
        method: 'POST',
        data: params,
        useBffApi: true
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  /**
   * @description 更改购物袋商品
   * @param {*} opts 仅为接口入参
   */
  updateGoodStatusHttp({ state, getters }, opts = {}) {
    const params = {
      cart_id: opts.cart_id,
      final_quantity: opts.final_quantity ?? '', // 仅更新商品数量需要传参
      operation: opts.operation, // 移到收藏夹=delete_to_wish，删除商品=delete，更新商品数量=update_quantity
      address_id: state.checkoutParams.address_id,
      is_shop_transit: getters.isShopTransit,
      shop_transit_country_id: state.checkoutParams.shop_transit_country_id,
      prime_product_code: state.checkoutParams.prime_product_code,
      scene: state.checkoutParams.scene,
      checkout_no: state.checkoutParams.checkout_no,
      origin_quantity: opts.origin_quantity,
      originalPriceGoodsIdList: state.shoppingBagsState.originalPriceGoodsIdList
    }

    return new Promise((resolve, reject) => {
      schttp({
        url: `/order/shoppingBag/update`,
        method: 'POST',
        data: params,
        useBffApi: true
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  /**
   * @description 删除购物车商品接口
   * @param {*} opts 仅为购物车接口入参
   */
  deleteCartsRequest({ state }, opts = {}) {
    const params = {
      ids: opts.ids,
      checkout_no: state.checkoutParams.checkout_no,
      scene: !!state.checkoutParams.checkout_no ? 'buy_now' : ''
    }

    return new Promise((resolve, reject) => {
      schttp({
        method: 'POST',
        url: '/order/mall/cart_delete',
        data: params,
        useBffApi: true
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  /**
   * @description 将购物车商品移入收藏夹接口
   * @param {*} opts 仅为购物车接口入参
   */
  moveCartsWishRequest({}, opts = {}) {
    return new Promise((resolve, reject) => {
      schttp({
        method: 'POST',
        url: `/order/cart/cart_batch_to_wish?id[]=${opts.ids}&is_old_version=0&need_drop=1`,
        useBffApi: true
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // checkout接口请求carts相关报错处理
  handleCartsResp({ commit }, { code, res }) {
    if (code === 300401) {
      // 空车弹窗
      commit('updateCommonDialogState', {
        nullCartDialog: {
          show: true,
          tip: res.msg
        }
      })

      return
    }

    if (code === 300407) {
      // 购物车限制弹窗
      commit('updateCommonDialogState', {
        cartLimitDialog: {
          show: true,
          tip: res.msg
        }
      })

      return
    }
  }
}
