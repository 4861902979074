import { getCurrentInstance, h } from 'vue'
import { useRoute } from 'vue-router'

const isdebug = typeof window !== 'undefined' && gbCommonInfo.isDebug
let globalConfig = {
  screenElement: '#prerender-app', // 上屏元素
  log: isdebug, // 是否开启日志
  performance: isdebug, // 是否开启性能监控
}
if (typeof window !== 'undefined') {
  window._spaHydrationPlugin = globalConfig
}

class Subscriber {
  constructor() {
    this.subscribers = []
  }
  subscribe(callback) {
    if (typeof callback === 'function') {
      this.subscribers.push(callback)
    } else {
      throw new Error('Callback must be a function')
    }
  }
  unsubscribe(callback) {
    const index = this.subscribers.indexOf(callback)
    if (index !== -1) {
      this.subscribers.splice(index, 1)
    }
  }
  trigger(...args) {
    this.subscribers.forEach(callback => {
      callback(...args)
    })
  }
}

function checkModule(module) {
  if (!module.routeName || !module.request) {
    throw new Error('routeName, request 是必须的', module)
  }
  return true
}

function getHtmlWithStringSearch(htmlStr, startCommentStr, endCommentStr) {
  if (!htmlStr) return ''
  const startIndex = htmlStr.indexOf(`<!--${startCommentStr}-->`)
  // 使用lastIndexOf从后向前查找结束标记,性能更好
  const endIndex = htmlStr.lastIndexOf(`<!--${endCommentStr}-->`)
  if (startIndex === -1 || endIndex === -1 || endIndex <= startIndex) return ''
  const startCommentLength = `<!--${startCommentStr}-->`.length
  return htmlStr.substring(startIndex + startCommentLength, endIndex).trim()
}

function findSpecificCommentFromEnd(node, commentContent) {
  if (
    node.nodeType === Node.COMMENT_NODE &&
    node.nodeValue.trim() === commentContent
  ) {
    return node
  }
  let children = node.childNodes
  for (let i = children.length - 1; i >= 0; i--) {
    let found = findSpecificCommentFromEnd(children[i], commentContent)
    if (found) return found
  }
  return null
}

function logger(type, msg) {
  if (!globalConfig.log) return
  if (type === 'info') {
    console.log(
      `%c __SPA_HYDRATION_INFO__ \n ${msg}`,
      'color: #1890ff;font-size: 12px;',
    )
  } else if (type === 'warn') {
    console.warn(`__SPA_HYDRATION_WARN__ \n: ${msg}`)
  }
}

let perf = typeof window !== 'undefined' && window.performance
function startMeasure(name, routerName) {
  if (globalConfig.performance && perf) {
    perf.mark(`spa-hydration-${name}${routerName}`)
  }
}
function endMeasure(name, routerName) {
  if (globalConfig.performance && perf) {
    const startTag = `spa-hydration-${name}${routerName}`
    const endTag = startTag + `:end`
    perf.mark(endTag)
    perf.measure(`<spa-hydration> ${name}`, startTag, endTag)
    perf.clearMarks(startTag)
    perf.clearMarks(endTag)
  }
}

let _screenElement = null
const START_COMMENT_NAME = '__spaHydrationStart__'
const END_COMMENT_NAME = '__spaHydrationEnd__'
const moduleMap = new Map()
const rootChangeSubscriber = new Subscriber()
let currentRoute = null      // 当前展示的路由
let failureCallback = null
let oneAfterRouteChangeCallback = null
function setOneAfterRouteChangeCallback(callback) {
  oneAfterRouteChangeCallback = callback
}
function triggerOneAfterRouteChangeCallback({ to, from }) {
  if (oneAfterRouteChangeCallback && moduleMap.has(to.name)) {
    oneAfterRouteChangeCallback({ to, from, el: getRootElement() })
    oneAfterRouteChangeCallback = null
  }
}

function getRootElement() {
  if (!_screenElement) {
    _screenElement = document.querySelector(globalConfig.screenElement)
  }
  return _screenElement
}

/**
 * @description: 切换页面展示状态
 * @param {Boolean} display 是否展示
 */
const togglePageViewDisplay = (display) => {
  rootChangeSubscriber.trigger(display, { to: currentRoute })
}

function createMatchHydrationRoute({ routes, modules, globalConfig: config }) {
  if (typeof window === 'undefined') return routes
  Object.assign(globalConfig, config)
  const originModuleMap = modules.reduce((map, module) => {
    if (!checkModule(module)) return map
    map.set(module.routeName, module)
    return map
  }, new Map())
  // 只针对第一层路由走单页水合
  routes.forEach(route => {
    if (!originModuleMap.has(route.name)) return
    const moduleObj = originModuleMap.get(route.name)
    const beforeEnter = route.beforeEnter
    route.beforeEnter = (to, from, next) => {
      if (beforeEnter) {
        beforeEnter(to, from, (...args) => {
          if (args.length) {
            return next(...args)
          }
          routerComponentBeforeEnter(to, from, next)
        })
      } else {
        routerComponentBeforeEnter(to, from, next)
      }
    }
    if (!moduleObj.scheduler) {
      moduleObj.scheduler = (result, fn) => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => fn(result))
        })
      }
    }
    if (route.meta && route.meta.keepAlive) {
      moduleObj.keepAlive = true
    }
    moduleMap.set(route.name, moduleObj)
  })
  return routes
}

/**
 * @description: 监听全局路由前置钩子
 */
function listenGlobalRouteBeforeEnter(router) {
  if (!router || typeof window === 'undefined') return router
  router.beforeEach((to, from, next) => {
    currentRoute = to
    if (failureCallback) {
      failureCallback()
      failureCallback = null
    }
    triggerOneAfterRouteChangeCallback({ to, from })
    next()
  })
}

function routerComponentBeforeEnter(to, from, next) {
  const module = moduleMap.get(to.name)
  const isSsr = !from?.name
  if (isSsr || module.isLoaded || !module._fetch) {
    // keepAlive页面添加已加载的标识，不在单页水合请求。
    module.keepAlive && moduleMap.set(to.name, { ...module, isLoaded: true }) 
    next()
    return
  }
  const fetchDone = () => {
    // 判断是否有上屏的元素，可能设置了骨架屏。
    if (getRootElement()) {
      getRootElement().innerHTML = ''
    }
    next()
  }
  
  module._fetch
    .then(result => {
      const routerName = to.name
      endMeasure('request', routerName)
      if (!result) return fetchDone()
      if (routerName !== currentRoute.name) {
        // issue: 由于接口是异步请求的，需在接口响应完后判断一下当前的路由是否还是to， 如果不是就不上屏了。
        logger('info', '当前路由已经切换，取消上屏')
        return fetchDone()
      }
      // 在同一个页面来回切换; module._fetch是否相等，相等就不执行，不相等就执行。
      if (moduleMap.get(routerName)._fetch !== module._fetch) {
        logger('info', '当前页面有了新请求，此处请求作废')
        return next()
      } 
      moduleMap.set(routerName, { ...module, response: result })
      // module中判断是否要过滤html，从startCommentStr到endCommentStr之间的内容。 默认为true，进行过滤，否则不过滤。
      // 判断这个在module中的key为notFilterHtml。
      togglePageViewDisplay(false) 
      startMeasure('screen', routerName)
      // 如果module.scheduler.length 3个参数，此时就表示要强制更新，否则就是正常更新。
      // 强制开启更新
      const forceUpdate = module.scheduler.length > 2
      const screenUpdate = renderHydrationContent(result, !!module.notFilterHtml, forceUpdate)
      // 将之前的页面隐藏
      if (!screenUpdate) {
        togglePageViewDisplay(true) 
        fetchDone()
        return
      }
      failureCallback = () => {
        if (getRootElement()) {
          getRootElement().innerHTML = ''
        }
        togglePageViewDisplay(true)
      }
      module.scheduler({ data: result, to, from }, () => {
        endMeasure('screen', routerName)
        startMeasure('setup', routerName)
        failureCallback = null
        next()
      }, screenUpdate)
    })
    .catch(err => {
      console.error(`单页水合请求失败,走正常的页面: ${err.message}`)
      fetchDone()
    })
}

/**
 * 渲染水合内容到页面
 * @param {Object} options - 配置选项
 * @param {string} options.html - HTML 内容
 * @param {string} options.style - 样式内容
 * @param {boolean} notFilterHtml - 是否不过滤 HTML
 * @returns {Function|null} - 更新函数或 null（如果渲染失败）
 */
function renderHydrationContent({ html, style } = {}, notFilterHtml = false, forceUpdate = false) {
  // 获取根元素
  const rootElement = getRootElement()
  if (!rootElement) {
    logger(`找不到上屏元素screenElement: ${globalConfig.screenElement}`)
    return null
  }
  
  /**
   * 处理并插入样式
   * @param {string} styleContent - 样式内容字符串
   * @returns {Array} - 插入的样式元素数组
   */
  function processStyles(styleContent) {
    if (!styleContent) return []
    const fragment = document.createRange().createContextualFragment(styleContent)
    const styleElements = fragment.querySelectorAll('style')
    // 将新样式元素插入到文档头部
    if (fragment.children.length) {
      document.head.appendChild(fragment)
    }
    return styleElements
  }
  
  /**
   * 渲染内容到页面
   * @param {Object} options - 配置选项  
   * @returns {Array|null} - 插入的样式元素数组或 null
   */
  function renderContent({ html, style, notFilterHtml }) {    
    const contentHtml = !notFilterHtml 
      ? getHtmlWithStringSearch(html, START_COMMENT_NAME, END_COMMENT_NAME)
      : html
      
    if (!forceUpdate && !contentHtml) {
      !notFilterHtml && logger('warn', '取消单页水合;请页面注册使用router-view包裹HydrateRouterView组件')
      return null
    }
    rootElement.innerHTML = contentHtml || ''
    return style ? processStyles(style) : []
  }
  
  // 初次渲染
  let currentStyleElements = renderContent({ html, style, notFilterHtml })
  if (currentStyleElements === null) return null
  // 页面滚动到顶部
  window.scrollTo(0, 0)
  
  return function update({ html, style } = {}, _notFilterHtml = notFilterHtml) {
    currentStyleElements?.forEach(_ => _.remove())
    currentStyleElements = renderContent({ 
      html, 
      style, 
      notFilterHtml: _notFilterHtml,
    })
    return currentStyleElements !== null
  }
}

/**
 * @description: 激活水合Vue
 * @returns {Object} module
 */
function setupHydrationVue() {
  if (typeof window === 'undefined') return
  const instance = getCurrentInstance()
  if (!instance) {
    logger('warn', 'getCurrentInstance() returned null. setupHydrationVue() must be called at the top of a setup function or beforeCreate')
    return
  }
  const route = useRoute()
  // 多页无route
  const routeName = route?.name
  if (!moduleMap.has(routeName)) {
    logger('warn', '当前组件没有水合模块.')
    return
  }
  const module = moduleMap.get(routeName)
  if (module.keepAlive) {
    if (module.isLoaded || instance.isMounted) return
    moduleMap.set(routeName, { isLoaded: true })
  }
  
  try {
    togglePageViewDisplay(true) 
    const rootElement = getRootElement()
    if (!rootElement?.innerHTML) return
    const _endElement = findSpecificCommentFromEnd(document.querySelector('#in-app'), END_COMMENT_NAME)
    if (!_endElement) {
      logger('warn', '找不到结束标记, 页面未接入组件')
      if (rootElement) {
        rootElement.innerHTML = ''
      }
      return
    }
    instance.vnode.el = rootElement.firstChild
    while (rootElement.firstChild) {
      _endElement.parentNode.insertBefore(rootElement.firstChild, _endElement)
    }
    logger('info', `页面开始接管水合`)
  } finally {
    requestAnimationFrame(() => endMeasure('setup', routeName))
    return module.response
  }
}

/**
 * @description: 触发水合SSR请求，将数据存储到map中，等到后续路由匹配到时候，直接取出数据
 * @param {String} routeName 路由名称
 * @param {Object} params 请求参数
 */
function triggerHydrationSsrRequest(routeName, params) {
  if (typeof window === 'undefined' || !moduleMap.has(routeName)) return
  const module = moduleMap.get(routeName)
  if (module.isLoaded) return
  moduleMap.set(routeName, { ...module, _fetch: module.request(params) })
  startMeasure('request', routeName)
  logger('info', `routeName:${routeName} 水合请求触发`)
}

/**
 * @description: 清空水合请求, 用于入口不是从单页水合入口进入时，清空之前的请求
 * */
function clearHydrationRequestsState(routerName) {
  if (typeof window === 'undefined') return
  if (!moduleMap.has(routerName)) {
    logger('warn', `routeName: ${routerName} 不存在`)
    return
  }
  moduleMap.set(routerName, { ...moduleMap.get(routerName), _fetch: null })
  logger('info', `routeName:${routerName} 水合请求清空.`)
}

/**
 * @description: 路由router-view容器组件
 * */
function HydrateRouterView() {
  const spaHydrationPlugin = {
    install: app => {
      app.component('HydrateRouterView', {
        name: 'HydrateRouterView',
        inheritAttrs: false,
        compatConfig: { MODE: 3 },
        setup(props, { slots }) {
          if (props.ssronly) {
            const isServer = typeof window === 'undefined'
            if (!isServer) {
              return () => slots.default ? slots.default() : null
            }
          }
          return () => {
            const slotContent = slots.default ? slots.default() : []
            const commentNodeBegin = h(
              Symbol.for('v-ndc'),
              {},
              START_COMMENT_NAME,
            )
            const commentNodeEnd = h(Symbol.for('v-ndc'), {}, END_COMMENT_NAME)
            return [commentNodeBegin, ...slotContent, commentNodeEnd]
          }
        },
      })
    },
  }
  return spaHydrationPlugin
}

/**
 * @description: 注册全局的根元素展示change事件
 * @param {Function} callback 回调函数
 */
function registerRootElementChange(callback) {
  if (typeof window === 'undefined') return
  rootChangeSubscriber.subscribe(callback)
}

/**
 * 获取是否可水合状态
 * @param {string} routeName 路由名称
 * @returns {boolean} 是否已经完成
*/
function spaHydratedState(routeName) {
  return moduleMap.has(routeName) && moduleMap.get(routeName).isLoaded
}

export {
  createMatchHydrationRoute,
  registerRootElementChange,
  listenGlobalRouteBeforeEnter,
  setupHydrationVue,
  triggerHydrationSsrRequest,
  clearHydrationRequestsState,
  HydrateRouterView,
  togglePageViewDisplay,
  setOneAfterRouteChangeCallback,
  spaHydratedState,
}
