import { LayoutType } from 'public/src/pages/checkout_new/pages/buy_together/utils/constant.js'

export default {
  // 控制随手购展示
  visibleBuyTogether({ abtInfo, buyNowNo, togetherBuy }) {
    const { APOLLO_CONFIG, togetherBuyType, OrderGoodsLocation } =
      togetherBuy || {}
    const configLocation = !!togetherBuyType ? +OrderGoodsLocation : 0

    const isNewUserCasualBuy =
      abtInfo?.NewUserCasualBuy?.param?.NewUserCasualBuy === 'new'
    const isOpenApollo =
      APOLLO_CONFIG?.CHECKOUT_SWITCH_BUY_TOGETHER_NOW === 'on'

    // 控制展示
    const visible = isNewUserCasualBuy ? false : buyNowNo ? isOpenApollo : true

    // 控制渲染位置
    return visible && configLocation !== 0
  },

  /************************************
   * @description [融合如下，推全上面的全删除]
   **********************************/

  // 控制随手购展示
  visibleBuyTogetherNew(
    { abtInfo, buyNowNo, buyTogetherState },
    { buyTogetherProductList },
  ) {
    const isNewUserCasualBuy =
      abtInfo?.NewUserCasualBuy?.param?.NewUserCasualBuy === 'new'

    // 控制展示
    const visible = isNewUserCasualBuy
      ? false
      : buyTogetherState.recommendGoods.isAddToOrderGoods &&
        buyTogetherState.recommendGoods.isAddToOrderGoods !== LayoutType.None &&
        !!buyTogetherProductList.length

    return visible
  },

  // 是否多商品
  isSingleBuyTogether({ buyTogetherState }) {
    // return [LayoutType.Single, LayoutType.SingleDetail].includes(
    //   buyTogetherState.recommendGoods.isAddToOrderGoods,
    // )
    // 备注：产品要求，PWA不要2，PC不要1.3，但是逻辑保留，指不定哪天产品会释放需求
    return true
  },

  // 随手购渲染数据
  buyTogetherProductList({ buyTogetherState }) {
    const list = buyTogetherState.recommendGoods?.productList || []
    // 渲染有库存商品
    const listInStock = list.filter(item => !item.soldOutStatus)
    return listInStock
  },
  // 随手购渲染类型，枚举值对应 LayoutType
  isAddToOrderGoods({ buyTogetherState }) {
    // 遍历 LayoutType，检查是否有值等于 isAddToOrderGoods
    return Object.entries(LayoutType).reduce((acc, [key, value]) => {
      acc[key] =
        buyTogetherState.recommendGoods?.isAddToOrderGoods === value
          ? true
          : value
      return acc
    }, {})
  },
}
