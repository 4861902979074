
export default {
  // 当前展示的地址
  getDefaultAddress: ({ addressBarState }) => {
    return addressBarState.defaultAddressCache || addressBarState.defaultAddress || null
  },

  // 是否有地址 true有 false无
  isHasAddress: ({}, getter) => {
    return !!getter.getDefaultAddress && Object.keys(getter.getDefaultAddress)?.length !== 0
  },
  
  // 是否是风控地址 0 非风控地址，1 风控地址
  isRiskAddress: ({ addressBarState }) => {
    return !!Number(addressBarState.defaultAddress?.sensitiveRule?.addressRisk) ? 1 : 0
  },

  /**
   * 是否店配
   * 最常用的，用在接口请求判断，唤醒弹窗判断等
   * currentIsStoreType当为-1的时候，即没有选择任何的运输方式，需要用接口返回的calculatePriceIsShopTransit等于1（店配）作为兜底
   */
  isShopTransit: ({ checkout }, { currentIsStoreType }) => {
    return +currentIsStoreType === 1 || (+currentIsStoreType === -1 && +checkout?.calculatePriceIsShopTransit === 1)
  },

  /**
   * 是否店配
   * 1、A => B，如果当前checkout报错，会暂时把运输方式替换成B，触发各种抽屉、弹窗等逻辑，但是这里需要的是A，用在顶部地址栏显示
   * 2、A => B，正常切换，即checkout不报错，即选中的运输方式B
   */
  isRealShopTransit: ({ checkout, shippingMethodState }, { lastShipIsStoreType, currentIsStoreType }) => {
    // console.debug('[useShippingSelect] isRealShopTransit currentIsStoreType: %s, lastShipIsStoreType: %s', currentIsStoreType.value, lastShipIsStoreType.value)
    
    return shippingMethodState.addressRelativeError ? lastShipIsStoreType === 1 :
      (+currentIsStoreType === 1 || (+currentIsStoreType === -1 && +checkout?.calculatePriceIsShopTransit === 1))
  },
  
  // 没有店配地址场景
  isNoShopAddressScene: (state, getter) => {
    return !getter.isHasAddress && getter.isShopTransit
  },

  /**
   * 无地址弹窗场景
   * '0': 直接跳转 h5, 线上逻辑
   * '1': 对应 abt value 'typeA', 没有地址时在下单页正常展示信息
   * '2': 对应 abt value 'typeB', 没有地址时在下单页正常展示信息，停留0.5秒后，自动弹出填写地址弹框
   * '3': 对应 abt value 'typeC', 没有地址时在下单页正常展示信息，停留0.5秒后，自动弹出填写地址弹框
   * '4': abt 是需要处理的, 但是地址匹配失败
   * '5': 有地址
   */
  noAddressScene: ({ checkout }) => {
    return checkout?.no_address_calculate_address_info?.no_address_scene
  },
  // 无地址场景，进入了下单页面
  isNotAddressScene: (state, getter) => {
    return [1, 2, 3].includes(Number(getter.noAddressScene)) && !getter.isHasAddress
  },
}
