import { SToast } from '@shein-aidc/sui-toast/mobile'

import schttp from '@/public/src/services/schttp'
import { isEqual } from '@/public/src/pages/checkout_new/utils'

export default {
  /**
   * @description 获取推荐数据
   * @param {*} opts
   */
  getRecommendGoods({ commit, state }, opts = {}) {
    const params = {
      last_goods_id: opts.last_goods_id,
      mall_code: opts.mall_code,
      quick_ship_flag: opts.quick_ship_flag,
      address_id: opts.address_id,
      scene: opts.scene,
    }
    return schttp({
      method: 'POST',
      url: '/order/checkout/recommend/goods',
      data: params,
      useBffApi: true,
    }).then(res => {
      const isSucceed = isEqual(res?.code, 0)
      if (isSucceed) {
        commit('changeBuyTogether', res.info)
        return res.info
      }
      SToast(state.language.SHEIN_KEY_PWA_27324)
      return {}
    })
  },
  /**
   * @description 单尺码直接加车
   * @param {*} opts
   */
  async addProductToCart({ commit, state }, opts = {}) {
    try {
      const buyNowNo = state.buyNowNo
      const params = {
        goods_id: opts.goods_id,
        trace_id: opts.trace_id,
        mall_code: opts.mall_code,
        sku_code: opts.sku_code,
        quantity: opts.quantity,
      }

      const res = !!buyNowNo
        ? await schttp({
            method: 'POST',
            url: '/api/checkout/postRapidBatchAddCart/create',
            data: {
              product_list: [params],
              // 页面来源：下单页随手购
              add_source: 12,
              checkout_no: buyNowNo,
            },
          })
        : await schttp({
            method: 'POST',
            url: '/api/cart/add_mall/create',
            data: {
              ...params,
              // 页面来源：下单页随手购
              add_source: 12,
            },
          })
      const isSucceed = isEqual(res?.code, 0)
      if (isSucceed) {
        SToast(state.language.SHEIN_KEY_PWA_15060)
        return { isSucceed: true, res }
      }
      return { isSucceed: false, res }
    } catch (error) {
      return { isSucceed: false, res: null }
    }
  },
}
