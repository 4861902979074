export default () => ({
  abtState: {
    // 新旧样式
    orderConfirmDiscountSwitch: false,
    placeOrderStatusMove: false,
    // 挽留弹窗
    isUseNewSwitchByAbt: false, // 是否使用CCC
    retainDialogTransProcStrategy: 'default', // 挽留弹窗跨流程保留策略
    // 控制气泡展示的时机和气泡的文案
    isBubbleCopyWriting: false,
    isShowNewActivityPopoverLayout: true,
    isShowActivityPopoverNewLayout: false,
    footerBtnBenefitLinkage: 'OFF',  // 交易链路上（购物车、下单页）展示气泡的策略
    // 开启总优惠金额展示
    isShowSaveAmount: false,
    placeOrderButtonPayStatus: false,
    bnplPlaceOrderStatus: false,
    placeOrderButtonUpgradeStatus: false,
    placeOrderFreeShippingstatus: false,
    PlaceOrderQuickshipWordStatus: false,
    LocationPrimeSaverStatus: false,

    // 填写地址的时候，显示的方式是跳转地址填写弹框
    isUsePopJumpAddressWrite: false,
    // 使用新版下单loading
    isUseNewCheckoutLoading: false,
    isOrderpaylistbottomstatus: false, // 支付方式楼层置底
    isOrderpaylistbottomnodefaultstatus: false, // 支付方式楼层置底, 又默认支付方式时用的 abt
    paymentSafetyExperimentAbt: {},
    // 运输
    addFreeShippingNewEntrance: 'typeOff',
    // 订单明细
    addFreeShippingInPriceDetail: false, // 增加免邮凑单入口
  }
})
