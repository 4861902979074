export default () => ({
  couponListState: {
    // 手动添加券
    cmpAddCouponCoupons: [],

    //最优券
    cmpBestCombinationCoupons: [],

    // 不可用券
    cmpDisableCoupons: [],

    // 可用券
    cmpUsableCoupons: [],

    // 券列表下沉组件ref引用
    BCouponListRef: null,

    // 当前使用的 卡 bin 优惠券信息
    nowUseCouponInfo: [],

    // 用券错误信息
    useCouponErrMsg: '',

    // 当前点击的券信息
    currentCoupon: null
  }
})
